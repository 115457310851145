<template>
  <CModal :show.sync="show" :title="$pgettext('checkin.modal.date_of_stay.title', 'Update Date of Stay')" centered>
    <PFRow :label="$pgettext('checkin.modal.date_of_stay.label','Start of Stay')" size="12x12">
      <VueCtkDateTimePicker
          v-model="form.start_of_stay"
          :no-label="true"
          :label="$gettext('Select date')"
          format="YYYY-MM-DD"
          formatted="LL"
          :only-date="true"
          :no-button-now="true"
          color="#1867FF"
      />
    </PFRow>
    <PFRow :label="$pgettext('checkin.modal.date_of_stay.label','End of Stay')" size="12x12">
      <VueCtkDateTimePicker
          v-model="form.end_of_stay"
          :no-label="true"
          :label="$gettext('Select date')"
          format="YYYY-MM-DD"
          formatted="LL"
          :only-date="true"
          :no-button-now="true"
          color="#1867FF"
      />
    </PFRow>
    <template #footer>
      <CButton color="secondary" @click="show = false" v-translate>Close</CButton>
      <PButton
          color="primary"
          v-translate translate-context="checkin.modal.date_of_stay.actions"
          class="mr-3"
          :disabled="!form.start_of_stay || !form.end_of_stay"
          :loading="saving"
          @click="update"
      >Update
      </PButton>
    </template>
  </CModal>
</template>

<script>
import {mapActions} from "vuex";
import PFRow from "@/domain/core/components/PFRow.vue";
import PButton from "@/components/PButton.vue";

export default {
  name: "DateOfStayModal",
  components: {PButton, PFRow},
  props: {
    start_of_stay: {
      type: String,
      required: true,
    },
    end_of_stay: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      saving: false,
      form: {
        start_of_stay: null,
        end_of_stay: null,
      },
    }
  },
  methods: {
    ...mapActions('checkin', [
      'updateDateOfStay',
    ]),
    async open() {
      this.saving = false
      this.form.start_of_stay = this.start_of_stay
      this.form.end_of_stay = this.end_of_stay
      this.show = true
      this.loading = false
    },
    async update() {
      this.saving = true
      await this.updateDateOfStay({
        start_of_stay: this.form.start_of_stay,
        end_of_stay: this.form.end_of_stay,
      })
      this.show = false
    }
  }
}
</script>