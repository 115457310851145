<template>
  <PTransparentLoader :loading="loading">
    <template v-if="checkin">
      <DateOfStayModal :end_of_stay="dateOfStay.end" :start_of_stay="dateOfStay.start" ref="modalDateOfStay"/>
      <CCard>
        <CCardHeader>
          <h5 class="main-header mb-1" v-translate translate-context="checkin.title">Checkin</h5>
        </CCardHeader>
        <CCardBody class="px-0">
          <CContainer fluid class="grid-stripped">
            <PFRow :label="$pgettext('checkin.label','Pica Code')">
              {{ checkin.picacode }}
            </PFRow>
            <PFRow :label="$pgettext('checkin.label','Available Photos')">
              <CLink :to="routeToSearchPhotos(checkin.picacode)">{{ checkin.available_photos_number|number }}</CLink>
            </PFRow>
            <PFRow :label="$pgettext('checkin.label','Date of Stay')">
              <CButton color="dark" class="btn-circle" variant="outline" size="sm"
                       @click="editDateOfStay"
                       v-if="hasPerm('dashboard.can_edit_checkins')"
              >
                <CIcon name="cipPencil" size="sm"></CIcon>
              </CButton>
              <div class="ml-2 d-inline" v-if="checkin.start_of_stay || checkin.end_of_stay">
                {{ checkin.start_of_stay|formatDate('LL', timeZone) }} - {{ checkin.end_of_stay|formatDate('LL', timeZone) }}
              </div>

            </PFRow>
          </CContainer>
        </CCardBody>
      </CCard>
    </template>
  </PTransparentLoader>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import PFRow from "@/domain/core/components/PFRow.vue";
import PTransparentLoader from "@/domain/core/components/PTransparentLoader.vue";
import DateOfStayModal from "@/domain/checkin/components/DateOfStayModal.vue";
import moment from "moment";
import PButton from "@/components/PButton.vue";

export default {
  name: "Checkin",
  components: {PButton, DateOfStayModal, PTransparentLoader, PFRow},
  data() {
    return {
      loading: true,
      start_of_stay: '',
      end_of_stay: '',
    }
  },
  computed: {
    ...mapGetters('event', [
      'picaServiceId',
      'timeZone'
    ]),
    ...mapGetters('checkin', [
      'checkin',
    ]),
    dateOfStay() {
      return {
        start: moment.tz(this.checkin.start_of_stay, this.timeZone).format('YYYY-MM-DD'),
        end: moment.tz(this.checkin.end_of_stay, this.timeZone).format('YYYY-MM-DD')
      }
    }
  },
  async mounted() {
    this.loading = true
    this.setPicaServiceId(this.picaServiceId)
    this.setCheckinId(this.$route.params.id)
    await this.fetchCheckin()
    this.loading = false
  },
  methods: {
    ...mapActions('checkin', [
      'fetchCheckin',
      'setPicaServiceId',
      'setCheckinId',
    ]),
    routeToSearchPhotos(picacode) {
      return {name: 'MediaSearch', params: {picaServiceId: this.picaServiceId}, query: {picacode: picacode}}
    },
    editDateOfStay() {
      this.$refs.modalDateOfStay.open()
    }
  }
}
</script>